import React from "react"
import icoCliente01 from "../assets/old/img/clientes-art/01.png"
import icoCliente02 from "../assets/old/img/clientes-art/02.png"
import icoCliente03 from "../assets/old/img/clientes-art/03.png"
import icoCliente04 from "../assets/old/img/clientes-art/04.png"
import icoCliente05 from "../assets/old/img/clientes-art/05.png"
import icoCliente06 from "../assets/old/img/clientes-art/06.png"
import icoCliente07 from "../assets/old/img/clientes-art/07.png"
import icoCliente08 from "../assets/old/img/clientes-art/08.png"
import icoCliente09 from "../assets/old/img/clientes-art/09.png"
import icoCliente10 from "../assets/old/img/clientes-art/10.png"

export default () => (
  <>
    <section className="container" data-offset-top="110" id="aseguradoras">
      <div className="container pt-1 mt-2 pb-5">
        <h2 className="h3 block-title text-center pt-5 mt-2">
          Clientes que confían en nosotros
        </h2>
        <div className="row pt-4">
          <div className="col-md-3 col-sm-4 col-6 mb-30">
              <img
                className="d-block mx-auto"
                src={icoCliente01}
                style={{ padding: "30px 0" }}
                alt="Clientes"
              />
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
              <img
                className="d-block mx-auto"
                src={icoCliente02}
                style={{ padding: "30px 0" }}
                alt="Clientes"
              />
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
              <img
                className="d-block mx-auto"
                src={icoCliente03}
                style={{ padding: "30px 0" }}
                alt="Clientes"
              />
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
              <img
                className="d-block mx-auto"
                src={icoCliente04}
                style={{ padding: "30px 0" }}
                alt="Clientes"
              />
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
              <img
                className="d-block mx-auto"
                src={icoCliente05}
                style={{ padding: "30px 0" }}
                alt="Clientes"
              />
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
              <img
                className="d-block mx-auto"
                src={icoCliente06}
                style={{ padding: "30px 0" }}
                alt="Clientes"
              />
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
              <img
                className="d-block mx-auto"
                src={icoCliente07}
                style={{ padding: "30px 0" }}
                alt="Clientes"
              />
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
              <img
                className="d-block mx-auto"
                src={icoCliente08}
                style={{ padding: "30px 0" }}
                alt="Clientes"
              />
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
              <img
                className="d-block mx-auto"
                src={icoCliente09}
                style={{ padding: "30px 0" }}
                alt="Clientes"
              />
          </div>
          <div className="col-md-3 col-sm-4 col-6 mb-30">
              <img
                className="d-block mx-auto"
                src={icoCliente10}
                style={{ padding: "30px 0" }}
                alt="Clientes"
              />
          </div>










        </div>
      </div>
    </section>
  </>
)
