import React from "react"
import { Helmet } from "react-helmet"
import { navigate } from "gatsby"
import Modal from "react-bootstrap/Modal"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Alert from "react-bootstrap/Alert"
import { Formik, Field } from "formik"
import PhoneInput from "react-phone-number-input/input"
import * as Yup from "yup"

import Layout from "../../../components/layout"
import ClientesArt from "../../../components/clientes-art"

import customerService from "../../../assets/old/img/art/customer-service.png";
import imgIlt from "../../../assets/old/img/art/ilt.png";
import imgWounded from "../../../assets/old/img/art/wounded.png";
import imgAnalysis from "../../../assets/old/img/art/analysis.png";
import imgOficialdocument from "../../../assets/old/img/art/official-documents.png";
import imgDollar from "../../../assets/old/img/art/dollar.png";
import imgChat from "../../../assets/old/img/art/chat.png";

export default class extends React.Component {
  state = {
    modalVermasShow: false,
    modalContinuarPorTelefonoShow: false,
    nombre: "",
    celular: "",
    cuit: 0,
    actividad: "",
    masaSalarial: 0,
    cantidadEmpleados: 0,
    alicuotaWds: 0,
    costoMensual: 0,
    token: '',
  }

  componentDidMount() {
    if (!this.props.location.state) {
      navigate("/seguros/cotizar-art")
      return false
    }
    const {
      nombre,
      celular,
      cuit,
      actividad,
      masaSalarial,
      cantidadEmpleados,
      alicuotaWds,
      costoMensual,
      token,
    } = this.props.location.state
    this.setState({
      nombre,
      celular,
      cuit,
      actividad: actividad[0].actividad,
      masaSalarial,
      cantidadEmpleados,
      alicuotaWds,
      costoMensual,
      token,
    })
  }

  handleContratar() {
    const { nombre, celular, email, cuit, token } = this.props.location.state
    navigate("/seguros/cotizar-art/contratado", {
      state: { nombre, celular, email, ...this.state },
      replace: true,
    })
    // enviar mail de contratación
    const asunto = `ART ¡CONTRATACIÓN PENDIENTE! ${nombre}`
    const cuerpo = `
      <p>Has recibido una nueva contratación desde tu sitio web.</p>
      <p>
        <a href="https://centrix.wokan.com.ar/acbytoken/${token}">
          Ver datos de la oportunidad en Wokan</a>.
      </p>
      <p>
        <b>**IMPORTANTE**</b> Ponte en contacto rápidamente con tu cliente
        ya que está esperando los detalles de la contratación.
      </p>
      <h2>Datos de la contratación</h2>
      <dl>
        <dt>Nombre</dt>
        <dd>${nombre}</dd>
        <dt>Correo electrónico</dt>
        <dd>${email}</dd>
        <dt>Celular</dt>
        <dd>${celular.replace(/^\+549?/, "")}</dd>
        <dt>CUIT</dt>
        <dd>${cuit}</dd>
      </dl>
    `
    const params = {asunto, cuerpo, responder: email, destino: "contratacion"}
    fetch(`${process.env.WOKAN_WEBPACK_ENPOINT}/mensajes`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "X-Wokan-Webpack-SID": `${process.env.WOKAN_WEBPACK_ART_SID}`,
        "Content-Type": "application/json",
      },
    })
  }

  render() {
    const {
      nombre,
      celular,
      cuit,
      actividad,
      masaSalarial,
      cantidadEmpleados,
      alicuotaWds,
      costoMensual,
      token,
    } = this.state

    return (
      <Layout menuActivo="cotizar-seguros">
        <Helmet>
          <title>Resultado Cotización ART</title>
          <meta
            name="description"
            content="Las Aseguradoras de Riesgo del Trabajo (ART) cubren dos tipos posibles de siniestros laborales."
          />
        </Helmet>

        <section className="pb-5 pt-5" id="cases">
          <div className="container pb-5 mb-3">
            <div className="row">
              {/* Blog Grid */}
              <div className="col-lg-12">
                <div className="card blog-card mb-2">
                  <h5
                    className="text-center bg-primary py-3 text-white"
                    style={{ marginBottom: 0 }}
                  >
                    Detalle de Póliza{" "}
                    <span role="img" aria-label="Herramienta">
                      🛠️
                    </span>
                  </h5>
                  <div className="card-body" style={{ padding: 0 }}>
                    <div className="row">
                      <div className="col-sm-4 py-4">
                        <h5 className="block-title text-center">Cotizado</h5>
                        <p
                          className="text-muted pad-min-siniestros text-center mt-4"
                          style={{ lineHeight: "30px" }}
                        >
                          <strong>CUIT:</strong> {cuit} <br />
                          <strong>Actividad:</strong> {actividad} <br />
                          <strong>Masa Salarial:</strong> ${masaSalarial} <br />
                          <strong>Cantidad de empleados:</strong> {cantidadEmpleados} <br />
                        </p>
                      </div>

                      <div className="col-sm-4 bg-secondary py-4">
                        <h5 className="block-title text-center">Mejor Alicuota Variable</h5>
                        <div className="text-muted pad-min-siniestros text-center">
                          <div className="page-title-procentaje text-success">{alicuotaWds}%</div>
                          <br />
                          <button
                            className="btn btn-style-6 btn-primary mr-3 mb-3 btn-sm"
                            onClick={() => {
                              this.setState({ modalVermasShow: true })
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            Ver detalle
                          </button>
                        </div>
                      </div>

                      <div className="col-sm-4 py-4">
                        <h5 className="block-title text-center">Costo Mensual</h5>
                        <div className="text-muted pad-min-siniestros text-center">
                          <div className="page-title-procentaje text-info">${costoMensual}</div>
                          <br />
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              if (window.gtag) {
                                window.gtag("event", "art_contratar")
                              }
                              this.handleContratar()
                            }}
                          >
                            ¡Contratar!
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button
                      className="btn btn-warning btn-sm"
                      type="button"
                      onClick={() => {
                        if (window.gtag) {
                          window.gtag("event", "art_continuar_telefono_ventana")
                        }
                        console.log("hoa")
                        this.setState({ modalContinuarPorTelefonoShow: true })
                      }}
                    >
                      <i className="fe-icon-phone mr-1"></i> Continuar por teléfono
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* COBERTURA */}
        <section className="pb-5 pt-1" id="cases">
          <h2 className="h3 block-title text-center mb-5">
            Beneficios de ser cliente
          </h2>
          <div className="container">
            <div className="row">
              <div className="col-lg-3"></div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <img src={customerService} alt="Ejecutivo de Cuentas" />
                  <span className="ml-3 text-uppercase">Asignación de un ejecutivo de cuentas para tu empresa</span>
                </div>
                <div className="mb-3">
                  <img src={imgIlt} alt="Gestión de reintegros" />
                  <span className="ml-3 text-uppercase">Presentación y gestión de reintegros de ilt</span>
                </div>
                <div className="mb-3">
                  <img src={imgWounded} alt="Seguimiento de siniestros" />
                  <span className="ml-3 text-uppercase">Seguimiento de siniestros</span>
                </div>
                <div className="mb-3">
                  <img src={imgAnalysis} alt="Análisis de cuentas" />
                  <span className="ml-3 text-uppercase">Análisis de cuentas corrientes y gestiones de planes de pago</span>
                </div>
                <div className="mb-3">
                  <img src={imgOficialdocument} alt="Emisión de certificados" />
                  <span className="ml-3 text-uppercase">Emisión de certificados de cobertura Con y sin cláusula de no repetición</span>
                </div>
                <div className="mb-3">
                  <img src={imgDollar} alt="Reducción de costos" />
                  <span className="ml-3 text-uppercase">Reducción de costos – re cotización anual de tu seguro de ART</span>
                </div>
                <div className="mb-3">
                  <img src={imgChat} alt="Atención al cliente" />
                  <span className="ml-3 text-uppercase">Atención al cliente a través teléfono, mail y whatsapp business</span>
                </div>

              </div>
              <div className="col-lg-3"></div>
            </div>
          </div>
        </section>

        {/* Aseguradoras */}
        <ClientesArt />

        {/* MODAL DETALLES */}
        <Modal
          show={this.state.modalVermasShow}
          onHide={() => {
            this.setState({ modalVermasShow: false })
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ marginBottom: 0 }}>Detalles</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "0" }}>
            <div className="col-sm-12 py-4">
              <p>
                El costo expresado es calculado estadísticamente entre distintas combinaciones de
                actividades, sueldos promedio y alícuotas de mercado, siendo necesario al momento de
                contratar validar los datos porque{" "}
                <strong className="text-primary">
                  el ahorro puede llegar a ser todavía mayor.
                </strong>
              </p>

              <h5 className="h5 block-title text-center mb-2 mt-4">Cobertura</h5>

              <p>
                Las Aseguradoras de Riesgo del Trabajo (ART) cubren dos tipos posibles de siniestros
                laborales: los accidentes laborales y enfermedades profesionales.
              </p>
              <p>
                Los accidentes laborales: son los siniestros imprevistos que tienen lugar durante o
                con motivo del trabajo realizado.
              </p>
              <p>
                Los accidentes 'in itinere': son accidentes de trabajo, pero que no tienen lugar en
                el establecimiento laboral, sino en algún punto intermedio dentro del trayecto
                habitual entre el domicilio del trabajador y su establecimiento de trabajo (ya sea
                de ida o de vuelta).
              </p>
              <p>
                Las enfermedades profesionales: son aquellas enfermedades causadas directa y
                exclusivamente por los agentes de riesgo vinculados al tipo de trabajo realizado o
                presentes en el establecimiento laboral.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        {/* MODAL CONTINUAR POR TELEFONO */}
        <ModalContinuarPorTelefono
          show={this.state.modalContinuarPorTelefonoShow}
          formsData={{ celular, nombre, token }}
          handleClose={() => {
            this.setState({ modalContinuarPorTelefonoShow: false })
          }}
        />
      </Layout>
    )
  }
}

const ModalContinuarPorTelefono = ({ show, handleClose, formsData }) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title style={{ marginBottom: 0 }}>Continuar por teléfono</Modal.Title>
    </Modal.Header>
    <Formik
      isInitialValid={true}
      initialValues={{
        celular: formsData.celular,
        whatsapp: false,
        horario: "",
        nombre: formsData.nombre,
      }}
      initialStatus={{
        enviado: false,
      }}
      validationSchema={() =>
        Yup.object().shape({
          celular: Yup.string()
            .trim()
            .matches(/^\+549?\d{10}$/, "Ingrese código de área y número")
            .required("Requerido"),
          nombre: Yup.string().required("Requerido"),
        })
      }
      onSubmit={(values, { setSubmitting, setStatus }) => {
        if (window.gtag) {
          window.gtag("event", "art_continuar_telefono_enviado")
        }
        const asunto = `ART ¡SOLICITUD DE CONTACTO! ${values.nombre}`
        const cuerpo = `
          <p>Has recibido una nueva solicitud de contacto desde tu sitio web.</p>
          <p>
            <a href="https://centrix.wokan.com.ar/acbytoken/${formsData.token}">
              Ver datos de la oportunidad en Wokan</a>.
          </p>
          <h2>Datos de la solicitud</h2>
          <dl>
            <dt>Nombre</dt>
            <dd>${values.nombre}</dd>
            <dt>Celular</dt>
            <dd>${values.celular.replace(/^\+549?/, "")}</dd>
            <dt>Horario de contacto</dt>
            <dd>${values.horario ? values.horario : "En cuanto antes"}</dd>
            <dt>Prefiere por WhatsApp</dt>
            <dd>${values.whatsapp ? "Si" : "No"}</dd>
          </dl>
        `
        const params = { asunto, cuerpo, responder: formsData.email }
        fetch(`${process.env.WOKAN_WEBPACK_ENPOINT}/mensajes`, {
          method: "POST",
          body: JSON.stringify(params),
          headers: {
            "X-Wokan-Webpack-SID": `${process.env.WOKAN_WEBPACK_ART_SID}`,
            "Content-Type": "application/json",
          },
        }).then(response => {
          setStatus({ enviado: true })
          setSubmitting(false)
        })
      }}
    >
      {({ handleSubmit, isSubmitting, isValid, status, handleBlur, setFieldValue }) => (
        <>
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              {status.enviado ? (
                <Alert variant="success">
                  ¡Confirmado! Nos contactaremos en el horario indicado.
                </Alert>
              ) : (
                <Alert variant="secondary">Confirme sus datos de contacto por favor.</Alert>
              )}
              <Form.Group controlId="celular">
                <Form.Label>Celular</Form.Label>
                <Field name="celular">
                  {({ field, form, meta }) => (
                    <>
                      <PhoneInput
                        name={field.name}
                        value={field.value}
                        onChange={val => {
                          setFieldValue(field.name, val)
                        }}
                        onBlur={async e => {
                          handleBlur(e)
                        }}
                        country="AR"
                        placeholder="Ej: 11 7777-8888"
                        className={`form-control ${form.errors[field.name] &&
                          form.touched[field.name] &&
                          " is-invalid"}`}
                      />
                      {form.touched[field.name] && form.errors[field.name] && (
                        <Form.Control.Feedback type="invalid">
                          {form.errors[field.name]}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
              <Form.Group controlId="whatsapp">
                <Field name="whatsapp">
                  {({ field }) => (
                    <Form.Check {...field} type="checkbox" label="Prefiero contacto por WhatsApp" />
                  )}
                </Field>
              </Form.Group>
              <Form.Group>
                <Form.Label>Horario de contacto</Form.Label>
                <Field name="horario">
                  {({ form, field }) => (
                    <>
                      <Form.Control
                        {...field}
                        placeholder="Ej: Entre 9 y 17"
                        isInvalid={form.touched[field.name] && form.errors[field.name]}
                      />
                      {form.touched[field.name] && form.errors[field.name] && (
                        <Form.Control.Feedback type="invalid">
                          {form.errors[field.name]}
                        </Form.Control.Feedback>
                      )}
                      <Form.Text className="text-muted">
                        Si deja en blanco será contactado en cuanto antes.
                      </Form.Text>
                    </>
                  )}
                </Field>
              </Form.Group>
              <Form.Group>
                <Form.Label>Nombre y apellido</Form.Label>
                <Field name="nombre">
                  {({ form, field }) => (
                    <>
                      <Form.Control
                        {...field}
                        isInvalid={form.touched[field.name] && form.errors[field.name]}
                      />
                      {form.touched[field.name] && form.errors[field.name] && (
                        <Form.Control.Feedback type="invalid">
                          {form.errors[field.name]}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cerrar
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting || !isValid || status.enviado}
              >
                {isSubmitting ? "Un momento..." : status.enviado ? "Enviado" : "Confirmar"}
              </Button>
            </Modal.Footer>
          </Form>
        </>
      )}
    </Formik>
  </Modal>
)
